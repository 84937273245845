.steckbriefe {
  flex-wrap: wrap;
  display: flex;
}

.steckbriefe article {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  flex-direction: column;
  margin: .5em;
  display: flex;
}

.sb {
  height: calc(100% - 45px);
  padding: 1em;
}

.sb-front {
  background-blend-mode: overlay;
  background-image: url("wappen_silber.399b970f.png");
  background-position: 145px 107px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-clip: content-box;
  flex-direction: row;
  display: flex;
}

article.auslese .sb-front {
  background-image: url("wappen_gold.e11596ba.png");
}

.sb-flasche {
  float: left;
  grid-area: Flasche;
  max-height: 100%;
}

.sb-glas {
  grid-area: Glas;
  align-self: flex-start;
  max-height: 8em;
  top: auto;
  bottom: 0;
}

.sb-front header {
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  border-left: 6px solid;
  grid-area: Text;
  align-self: start;
  padding-left: 3px;
  font-size: larger;
  line-height: 1.1em;
}

.btn-geschmack .btn {
  border-bottom: 2px solid #0000;
}

.trocken .sb-front header {
  border-color: #b8860b;
}

.trocken .geschmack th {
  color: #b8860b;
}

.btn.trocken {
  border-bottom-color: #b8860b;
}

.halbtrocken .sb-front header {
  border-color: green;
}

.halbtrocken .geschmack th {
  color: green;
}

.btn.halbtrocken {
  border-bottom-color: green;
}

.lieblich .sb-front header {
  border-color: #8b0000;
}

.lieblich .geschmack th {
  color: #8b0000;
}

.btn.lieblich {
  border-bottom-color: #8b0000;
}

.sb-front-right {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.sb-front header div:first-child {
  font-weight: 300;
}

.sb-front header div:nth-child(2), .sb-front header div:nth-child(3) {
  font-weight: 400;
}

.sb-front header div:nth-child(4) {
  font-weight: 900;
}

.wappen {
  color: #daa520;
  grid-area: Wappen;
  height: 4em;
  margin-right: .5em;
}

.sb-back {
  flex-direction: column;
  display: flex;
}

.sb-back header {
  color: #006400;
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 900;
}

.sb-back aside {
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.sb-back > div {
  align-items: center;
  padding-bottom: 1em;
  display: flex;
}

.sb-back table {
  margin: 0;
  font-size: smaller;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
}

.sb-back table tr td {
  width: 1%;
}

.pricetag {
  text-align: center;
  background-color: #d3d3d3;
  border: none;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 45px;
  font-size: 1.25em;
  transition: all .6s;
  display: flex;
  position: absolute;
  bottom: 0;
}

.hide-me {
  display: none !important;
}

.pricetag-btn {
  cursor: pointer;
  background: none;
  border: none;
}

h3.pricetag-price {
  margin: 0;
  padding-top: 5px;
  font-size: 1em;
}

.pricetag-btn:hover {
  color: green;
}

.pricetag-prices p {
  color: #747474;
  margin: 0;
  font-size: 12px;
}

/*# sourceMappingURL=shop.485d06d6.css.map */
