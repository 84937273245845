.steckbriefe {
  display: flex;
  flex-wrap: wrap;
}

.steckbriefe article {
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 0.5em;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.sb {
  padding: 1em;
  height: calc(100% - 45px);
}

.sb-front {
  display: flex;
  flex-direction: row;
  background-image: url(../img/wappen_silber.png);
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: 180% 180%;
  background-blend-mode: overlay;
  background-position: 145px 107px;
  background-clip: content-box;
}

article.auslese .sb-front {
  background-image: url(../img/wappen_gold.png);
}

.sb-flasche {
  grid-area: Flasche;
  float: left;
  max-height: 100%;
}

.sb-glas {
  grid-area: Glas;
  max-height: 8em;
  bottom: 0;
  top: auto;
  align-self: flex-start;
}

.sb-front header {
  grid-area: Text;
  font-size: larger;
  line-height: 1.1em;
  border-left: 6px solid;
  align-self: start;
  padding-left: 3px;

  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.btn-geschmack .btn {
  border-bottom: 2px solid transparent;
}
.trocken .sb-front header {
  border-color: darkgoldenrod;
}
.trocken .geschmack th {
  color: darkgoldenrod;
}
.btn.trocken {
  border-bottom-color: darkgoldenrod;
}

.halbtrocken .sb-front header {
  border-color: green;
}
.halbtrocken .geschmack th {
  color: green;
}
.btn.halbtrocken {
  border-bottom-color: green;
}

.lieblich .sb-front header {
  border-color: darkred;
}
.lieblich .geschmack th {
  color: darkred;
}
.btn.lieblich {
  border-bottom-color: darkred;
}

.sb-front-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sb-front header div:nth-child(1) {
  font-weight: 300;
}
.sb-front header div:nth-child(2) {
  font-weight: 400;
}
.sb-front header div:nth-child(3) {
  font-weight: 400;
}
.sb-front header div:nth-child(4) {
  font-weight: 900;
}

.wappen {
  grid-area: Wappen;
  height: 4em;
  margin-right: 0.5em;
  color: goldenrod;
}

.sb-back {
  display: flex;
  flex-direction: column;
}
.sb-back header {
  font-size: 1.25rem;
  color: darkgreen;
  font-weight: 900;
  text-transform: uppercase;
}
.sb-back aside {
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.sb-back > div {
  display: flex;
  align-items: center;
  padding-bottom: 1em;
}
.sb-back table {
  font-size: smaller;
  overflow-y: scroll;
  overflow-x: hidden;
  display: block;
  margin: 0;
}

.sb-back table tr td {
  width: 1%;
}

.pricetag {
  position: absolute;
  bottom: 0;
  background-color: lightgrey;
  border: none;
  text-align: center;
  height: 45px;
  width: 100%;
  transition: 0.6s;
  font-size: 1.25em;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.hide-me {
  display: none !important;
}

.pricetag-btn {
  background: none;
  border: none;
  cursor: pointer;
}

h3.pricetag-price {
  font-size: 1em;
  margin: 0;
  padding-top: 5px;
}

.pricetag-btn:hover {
  color: green;
}

.pricetag-prices p {
  font-size: 12px;
  margin: 0;
  color: rgb(116, 116, 116);
}
